import React, { useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getUsersQuery, IUser, usersState } from "../atoms/users";

const columns: ColumnsType<IUser> = [
  {
    title: "이름",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "이메일",
    dataIndex: "email",
    key: "email",
    render: (email) => <Link to={`/user/${email}`}>{email}</Link>,
  },
  {
    title: "닉네임",
    dataIndex: "nickname",
    key: "nickname",
  },
  {
    title: "장애유형",
    dataIndex: "card",
    key: "disableTypes",
    render: (card) => <div>{card.disableTypes.join(", ")}</div>,
  },
  {
    title: "장애등급",
    dataIndex: "card",
    key: "disableDegree",
    render: (card) => <div>{card.disableDegree}</div>,
  },
  {
    title: "소득분위",
    dataIndex: "incomeLevel",
    key: "incomeLevel",
    render: (incomeLevel) => (
      <div>
        {incomeLevel}
        {incomeLevel && "%"}
      </div>
    ),
  },
];

function UserList() {
  const users = useRecoilValue(getUsersQuery);
  const setUsers = useSetRecoilState(usersState);

  useEffect(() => {
    setUsers(users);
  }, [users]);

  return <Table columns={columns} dataSource={users} />;
}

export default UserList;
