import { atom } from "recoil";

export interface Auth {
  token: string;
}

export const authState = atom<Auth | null>({
  key: "authState",
  default: null,
});
