import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Layout, message } from "antd";
import styled from "styled-components";
import { authState } from "atoms/auth";
import { useSetRecoilState } from "recoil";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import axios from "axios";
import { Logo } from "./style";

const { Header, Content } = Layout;

const FormWrapper = styled(Form)`
  padding-top: 100px;
  max-width: 600px;
  margin: auto;
  padding-bottom: 100px;
`;

// bss.rol20@gmail.com
function Login() {
  // const [logined, setLogined] = useState(false);
  const [error, setError] = useState("");
  const setUser = useSetRecoilState(authState);

  // state
  const onFinish = async (values: any) => {
    try {
      const { email, password } = values;
      const response = await axios.post("https://dangjanghe.com/user/login", {
        email,
        password,
      });

      const { token } = response.data.data;

      setUser({
        token,
      });
    } catch (err) {
      message.error("로그인 실패하였습니다.");
    }
  };

  const testEmail = "bss.rol20@gmail.com";
  return (
    <Layout>
      <Header className="header">
        <Logo>당장해</Logo>
      </Header>
      <Content>
        <FormWrapper
          name="basic"
          wrapperCol={{ span: 16, offset: 4 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            initialValue={testEmail}
            rules={[{ required: true, message: "이메일을 입력해주세요" }]}
          >
            <Input prefix={<MailOutlined />} placeholder="이메일" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "비밀번호를 입력해주세요" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="비밀번호"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </FormWrapper>
      </Content>
    </Layout>
  );
}

export default Login;
