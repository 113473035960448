import React from "react";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { Descriptions, PageHeader, Row, Statistic, Table, Image } from "antd";
import { getUsersQuery, IUser } from "../atoms/users";

interface IUserDetail {
  user: IUser;
}

function UserDetail({ user }: IUserDetail) {
  const userType = (type: string) => {
    if (type === "me") return "본인";
    return "가족";
  };

  const disableType = (types: string[]) => {
    return types.join(", ");
  };

  const parseDate = (date: string) => {
    return new Date(date).toLocaleDateString();
  };

  const address = () => {
    const { district, city, town, village } = user.card;
    return `${district}-${city}-${town}-${village}`;
  };

  return (
    <div>
      <PageHeader className="site-page-header" title={user.email}>
        <Descriptions
          size="small"
          column={3}
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Descriptions.Item label="이름">{user.name}</Descriptions.Item>
          <Descriptions.Item label="닉네임">{user.nickname}</Descriptions.Item>
          <Descriptions.Item label="성별">{user.gender}</Descriptions.Item>
          <Descriptions.Item label="유저 타입">
            {userType(user.userType)}
          </Descriptions.Item>

          <Descriptions.Item label="핸드폰">{user.phone}</Descriptions.Item>
          <Descriptions.Item label="생일">
            {parseDate(user.birthDate)}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          size="small"
          column={4}
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Descriptions.Item label="소득분위">
            {user.incomeLevel}%
          </Descriptions.Item>
          <Descriptions.Item label="14세 이상">
            {user.isFourteen ? "O" : "X"}
          </Descriptions.Item>
          <Descriptions.Item label="약관">
            {user.isTerms ? "O" : "X"}
          </Descriptions.Item>
          <Descriptions.Item label="마케팅">
            {user.isMarketing ? "O" : "X"}
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>

      <PageHeader className="site-page-header" title="카드 정보">
        <Descriptions
          size="small"
          column={3}
          style={{ backgroundColor: "white", padding: "20px" }}
        >
          <Descriptions.Item label="이름">{user.card.name}</Descriptions.Item>
          <Descriptions.Item label="성별">{user.card.gender}</Descriptions.Item>
          <Descriptions.Item label="장애 타입">
            {disableType(user.card.disableTypes)}
          </Descriptions.Item>
          <Descriptions.Item label="장애정도">
            {user.card.disableDegree}
          </Descriptions.Item>
          <Descriptions.Item label="등록일자">
            {parseDate(user.card.registrationDate)}
          </Descriptions.Item>
          <Descriptions.Item label="생년월일">
            {parseDate(user.card.birthDate)}
          </Descriptions.Item>
          <Descriptions.Item label="주소">{address()}</Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Image width={200} src={user.card.frontImageUrl} />
      <Image width={200} src={user.card.backImageUrl} />
    </div>
  );
}

export default UserDetail;
