import React from "react";
import LayoutWrapper from "../../components/layoutWrapper";

function Query() {
  return (
    <LayoutWrapper selectedIndex="2">
      <div>Query</div>
    </LayoutWrapper>
  );
}

export default Query;
