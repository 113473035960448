import { Navigate, Route, Routes } from "react-router-dom";
import { authState } from "atoms/auth";
import { useRecoilValue } from "recoil";
import Login from "./login/login";
import Home from "./home/home";
import Users from "./users/users";
import Query from "./query/query";
import User from "./users/user";

function App() {
  const auth = useRecoilValue(authState);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={auth === null ? <Login /> : <Navigate to="/home" />}
        />
        <Route path="/home" element={<Home />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user/:email" element={<User />} />
        <Route path="/query" element={<Query />} />
      </Routes>
    </div>
  );
}

export default App;
