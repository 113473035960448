import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import axios from "axios";
import { IUser, usersState } from "../../atoms/users";
import { authState } from "../../atoms/auth";
import LayoutWrapper from "../../components/layoutWrapper";

function Home() {
  const auth = useRecoilValue(authState);

  return (
    <LayoutWrapper selectedIndex="">
      <div>asdfasdf</div>
    </LayoutWrapper>
  );
}

export default Home;
