import type { MenuProps } from "antd";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRecoilState, useRecoilValue } from "recoil";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { getUsersQuery, IUser, usersState } from "../../atoms/users";
import { authState } from "../../atoms/auth";
import LayoutWrapper from "../../components/layoutWrapper";
import UserDetail from "../../components/userDetail";
import UserList from "../../components/userList";

const columns: ColumnsType<IUser> = [
  {
    title: "이름",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "이메일",
    dataIndex: "email",
    key: "email",
    render: (email) => <Link to={`/user/${email}`}>{email}</Link>,
  },
  {
    title: "닉네임",
    dataIndex: "nickname",
    key: "nickname",
  },
];

function Users() {
  return (
    <LayoutWrapper selectedIndex="1">
      <React.Suspense fallback={<div>Loading...</div>}>
        <UserList />
      </React.Suspense>
    </LayoutWrapper>
  );
}

export default Users;
