import React from "react";
import { Table } from "antd";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import LayoutWrapper from "../../components/layoutWrapper";
import { getUserQueryByEmail } from "../../atoms/users";
import UserList from "../../components/userList";
import UserDetail from "../../components/userDetail";

function User() {
  const { email } = useParams();
  const user = useRecoilValue(getUserQueryByEmail(email ?? ""));

  return (
    <LayoutWrapper selectedIndex="1">
      <UserDetail user={user} />
    </LayoutWrapper>
  );
}

export default User;
