import { atom, selector, selectorFamily } from "recoil";
import axios from "axios";
import { authState } from "./auth";

export interface IUser {
  name: string;
  email: string;
  nickname: string;
  gender: string;
  incomeLevel: number;
  isFourteen: boolean;
  isHouseHoldSkip: boolean;
  isMarketing: boolean;
  isPersonalInfo: boolean;
  isSignupFinished: boolean;
  isTerms: boolean;
  phone: string;
  uid: string;
  userType: string;
  birthDate: string;
  card: ICard;
}

interface ICard {
  frontImageUrl: string;
  backImageUrl: string;
  district: string;
  city: string;
  town: string;
  village: string;
  gender: string;
  name: string;
  registrationDate: string;
  fullText: string;
  birthDate: string;
  disableDegree: string;
  disableTypes: string[];
}

export const usersState = atom<IUser[]>({
  key: "usersState",
  default: [],
});

export const getUsersQuery = selector<IUser[]>({
  key: "getUsersQuery",
  get: async ({ get }) => {
    const auth = get(authState);
    const userState = get(usersState);
    if (userState.length !== 0) return userState;

    const response = await axios.get("https://dangjanghe.com/user/findAll", {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    });

    const users = response.data.data as IUser[];

    const filteredUsers = users.filter((e) => e.email !== null);

    const result = [] as IUser[];
    filteredUsers.forEach((e) => {
      if (result.findIndex((f) => f.email === e.email) === -1) {
        result.push(e);
      }
    });

    return result;
  },
});

export const getUserQueryByEmail = selectorFamily<IUser, string>({
  key: "getUserQueryByEmail",
  get:
    (email) =>
    ({ get }) => {
      const users = get(getUsersQuery);
      const findUsers = users.filter((e) => e.email === email);
      console.log(findUsers[0]);
      return findUsers[0] as IUser;
    },
});
