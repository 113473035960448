import { DesktopOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Breadcrumb, Layout, Menu } from "antd";
import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { layoutSelcetedState } from "../atoms/layout";

const { Header, Content, Footer, Sider } = Layout;

const SliderTitle = styled.div`
  font-size: 24px;
  color: orange;
  font-weight: bold;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

interface ILayoutWrapper {
  selectedIndex: string;
  children: JSX.Element;
}

function LayoutWrapper({ selectedIndex, children }: ILayoutWrapper) {
  return (
    <Layout
      style={{ minHeight: "100vh", display: "flex", flexDirection: "row" }}
    >
      <Sider>
        <SliderTitle>당장해 Admin</SliderTitle>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[selectedIndex]}>
          <Menu.Item key="1" icon={<UserOutlined />}>
            <Link to="/users">
              <div>유저 목록</div>
            </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<DesktopOutlined />}>
            <Link to="/query">
              <div>문의 관리</div>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Content style={{ margin: "0 16px" }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}> */}
          {/*  <Breadcrumb.Item>User</Breadcrumb.Item> */}
          {/*  <Breadcrumb.Item>Bill</Breadcrumb.Item> */}
          {/* </Breadcrumb> */}
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>Kangsters 주식회사</Footer>
      </Layout>
    </Layout>
  );
}

export default React.memo(LayoutWrapper);
